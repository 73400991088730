import React from "react"
import Layout from "@components/layout"
import SEO from "@components/seo"
import {
	LazyLoadImage,
	LazyLoadComponent,
} from "react-lazy-load-image-component"
import "react-lazy-load-image-component/src/effects/blur.css"

const NarmPractitioner3Page = () => (
	<Layout>
		<SEO
			title="NARM Practitioner Training Manual Module 3"
			description="NARM Practitioner Training Manual Module 3"
		/>
		<div class="page-headline">
			<div class="container">
				<div class="section-heading text-center">
					<h1>NARM Practitioner Training Manual Module 3</h1>
				</div>
			</div>
		</div>
		<div class="page-content narm-student">
			<div class="container">
				<div class="row">
					<div class="col-md-10 m-auto text-center">
						<LazyLoadImage
							effect="blur"
							src="/assets/img/module-3-square.png"
							class="img-fluid mb-4"
							alt="Narm Practitioner Training 3"
						/>
						<a
							className="btn btn-primary inverted shadow arrow"
							href="https://www.dropbox.com/s/n1psga9l0d4mc9m/NARM%20Training%20Module%203%20of%204%20-%20FULL%20MANUAL.pdf?dl=0"
							target="_blank"
						>
							Download Manual
						</a>
					</div>
				</div>
			</div>
		</div>
	</Layout>
)

export default NarmPractitioner3Page
